<template>
    <div class="grid gap-50">

        <div class="grid gap-20">

            <ActionBar title="Dashboard"></ActionBar>

            <div style="display: grid; gap: 20px; grid-template-columns: repeat(2, minmax(0, 1fr));">
                <StatsItem text="Outstanding Balance" icon="fa-solid fa-dollar-sign" :horizontal="false"
                    :loading="isLoading(['GetDashboardStats'])">${{
                        CONVERT_PRICE(Number(referralsStore.stats.outstanding.total).toFixed(2)) }}</StatsItem>

                <StatsItem text="Total Paid" icon="fa-solid fa-dollar-sign" :horizontal="false"
                    :loading="isLoading(['GetDashboardStats'])">${{
                        CONVERT_PRICE(Number(referralsStore.stats.paid.total).toFixed(2)) }}</StatsItem>
            </div>

        </div>


        <div class="grid gap-20">

            <ActionBar title="Loads"></ActionBar>


            <div class="item-list">
                <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)"
                    :loading="isLoading(['GetListOfLoads'])">
                    <LoadItem :item="item" :size="columnsSizes" :key="item._id"
                        v-for="(item, key) of _pageManager.list" />
                </TableView>


                <div v-else>
                    <div v-if="isLoading(['GetListOfLoads'])" class="spinner-flex">
                        <Spinner />
                    </div>
                    <div v-else>
                        <NoDataMessage text="Nothing found" />
                    </div>
                </div>
            </div>


            <div class="pagination">
                <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }" />
            </div>
        </div>


    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useReferralsStore } from '../../store/Referrals';

import StatsItem from '../../components/StatsItem.vue'


import PaginationJS from '../../mixins/Pagination'
import Pagination from '../../mixins/Pagination/components/Pagination.vue'

import LoadItem from './components/LoadItem.vue';


export default {
    mixins: [PaginationJS],
    components: {
        StatsItem,
        Pagination,
        LoadItem
    },
    data() {
        return {
            tableHead: [
                { name: 'Date' },
                { name: '' },

                { name: 'Rate' },
                { name: '' },

                { name: '' },
                { name: '' },

                { name: 'Carrier' },
                { name: '' },

                { name: 'Earned' },
                { name: '' },

                { name: 'Paid ?' },
            ],
            columnsSizes: [
                'minmax(50px, 75px)', // date
                '1px',

                'minmax(50px, 75px)', // rate
                '1px',

                'minmax(75px, 175px)', // origin/destination
                '1px',

                'minmax(150px, 1fr)', // carrier
                '1px',

                'minmax(50px, 75px)', // payment status
                '1px',

                'minmax(50px, 75px)', // payment status
            ]
        }
    },
    computed: {
        ...mapStores(useReferralsStore)
    },
    watch: {
        "_pageManager.query.search": {
            deep: true,
            handler() {
                this._pageManager.query.page = 1;
            }
        },
        "_pageManager.query": {
            deep: true,
            handler() {
                this.getUsers();
            }
        }
    },
    methods: {
        getStats() {
            this.ajax("GetDashboardStats", {
                url: '/referrals/user/stats',
                method: 'GET'
            }, (err, body) => {
                if (!err) {
                    this.referralsStore.setStats(body);
                }
            });
        },
        getLoads() {
            this.ajax("GetListOfLoads", {
                url: '/referrals/user/loads',
                method: 'GET'
            }, (err, body) => {
                if (!err) {
                    this._pageManager.pages = body.pages;
                    this._pageManager.count = body.count;
                    this._pageManager.list = body.list;
                    this._pageManager.page = body.page;
                    this._pageManager.hasNextPage = body.hasNextPage;
                    this._pageManager.hasPrevPage = body.hasPrevPage;
                }
            });
        }
    },
    mounted() {
        this.getLoads();
        this.getStats();
    }
}
</script>

<style lang="scss" scoped>
.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}
</style>